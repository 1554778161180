@import './mixin.scss';
@import './variables.scss';


.btn-button{
    @include Button_fill($header_subtext_icon_link_color,$white_color);
    margin-left:1rem;
    cursor: pointer;
    font-family: "DM Sans", sans-serif !important;
    font-weight: normal;

  &:hover{
    background-color: #45B39D;
    border-style: none;
    line-height: 20px;
    }
}

.btn-ouline{
  @include Button_outline($white_color);
  font-family: "DM Sans", sans-serif !important;
  font-weight: normal;
  &:hover{
    background-color: #45B39D;
    border-style: none;
    line-height: 20px;
  }
}

.btn-ouline-transparent{
  @include Button_outline($header_subtext_icon_link_color);
  font-family: "DM Sans", sans-serif !important;
  font-weight: normal;
  cursor: pointer;
  color: white;
  border-color: white;
  &:hover{
    @include Button_outline_Hover($header_subtext_icon_link_color,$white_color);
    background-color: #45B39D;
  }
}