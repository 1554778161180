@import './mixin.scss';
@import './variables.scss';


.Footer{
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
    background-color:$section_b_container_color;
   .Container{
    width: 90%;
    padding: 6rem 0rem;
    padding-bottom:1.6rem;
    box-sizing: border-box;
    overflow: hidden;
    margin-inline:auto ;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    ul{
        width: calc(100%/5);
        padding: 0px;
      li{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        img{
            width: 1.25em;
            margin-right: 0.5rem;
        }
        &.title{
          @include  footer_header($footer_header_color);
          font-family: 'DM Sans', sans-serif!important;
          margin-bottom: 1.2rem;
          font-weight: 700;
        }
        @include Checkbox_text($card_text_color); 
        font-family: 'DM Sans', sans-serif!important;
        margin-bottom:0.5rem;
        a{
            color: $card_text_color;
        }
      }  
    }
   }
   .logo_box{
    width: 90%;
    margin-inline: auto;
    img{
      height: 35px;
    }
    ul{
      list-style: none;
      display: flex;
      margin: 0px;
      padding: 0px;
      margin-top: 1.2rem;
      li{
        margin-right:30px;
        a{
          text-decoration: none;
          @include Checkbox_text($card_text_color); 
          font-family: 'DM Sans', sans-serif!important;
        }
      }
    }
   }
   .social_box{
    width: 90%;
    margin-inline: auto;
    padding:.5rem 0px;
    margin-top: 3rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 2px solid $border_color;
    box-sizing: border-box;
    img{
      width: 20px;
      height:20px;
      margin-right:7px;
    }
    ul{
      list-style: none;
      display: flex;
      margin: 0px;
      padding: 0px;
      li{
        @include Card_subtitle($card_text_color); 
        font-family: 'DM Sans', sans-serif!important;
        display: flex;
        align-items:center;
        margin-right:15px;
      }
    }
    p{
      @include Card_subtitle($card_text_color); 
      font-family: 'DM Sans', sans-serif!important;
    }

   }
}

@media (max-width:768px){
  .Footer{
   .Container{
    width: 95%;
    flex-direction:row;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding-bottom: 3rem;
    ul{
        width:calc(100%/2);
        margin-bottom: 2rem;
        padding-left: 0px;
      li{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        img{
            width: 1.25em;
            margin-right: 0.5rem;
        }
        &.title{
          @include  footer_header($footer_header_color);
          font-family: 'DM Sans', sans-serif!important;
          margin-bottom: 1.2rem;
        }
        @include Checkbox_text($card_text_color); 
        font-family: 'DM Sans', sans-serif!important;
        margin-bottom:0.5rem;
        a{
            color: $card_text_color;
        }
      }  
    }
   }
   .social_box{
    width: 90%;
    margin-inline: auto;
    padding:2rem 0px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    ul{
      flex-direction: column;
      width: 100%;
      li{
        img{
          margin-right: 20px;
        }
      }
    }

   }
} 
}

@media (max-width:400px){
  .Footer{
    .Container{
      flex-direction: column;
    }
    .social_box{
      width: 95%;
      ul{
        li{
         display: flex;
         align-items: flex-start;
         flex-direction: column;
        }
      }
  
     }
  }
}

