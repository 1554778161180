@import './mixin.scss';
@import './variables.scss';


.Profile_Modal {
  width: 650px;
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;

  .Close_Div {
    position: absolute;
    top: 0rem;
    right: 0rem;

    svg {
      width: 14px;
      height: 14px;
      fill: $footer_header_color;
      font-family: DM Sans, sans-serif !important;
    }
  }

  img {
    width: 300px;
    aspect-ratio: 1;
    object-fit: cover;
  }

  .Right {
    width: 50%;
    height: auto;

    .Title {
      width: 80%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-right: auto;

      .Name {
        @include Header_Mobile($footer_header_color);
      }

      .btn-button {
        margin-left: 0rem;
      }
    }

    .Product {
      @include Header_text($header_top_subtext_color);
      font-family: sans-serif;
    }

    p {
      @include Header_text($header_top_subtext_color);
      font-family: sans-serif;
      text-align: left;
      margin: 0px;
    }

    .Detail {
      margin-top: 2rem;
      margin-bottom: 2rem;
      height: 100px;
      transition: height .4s ease-in-out;
      @include Header_text($header_top_subtext_color);
      font-family: sans-serif;
      text-align: left;
      overflow: hidden;

      &.active {
        margin-top: .7rem;
        margin-bottom: .5rem;
        @include Header_text($header_top_subtext_color);
        height: 270px;
      }
    }

    .btn-button {
      margin-left: 0rem;
    }
  }
}

.Calender_Modal {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
}

.Parcelflow_Forward {
  width: 100%;
  overflow: hidden;
  height: 80vh;
  @include Bg;
  background-color: #edffff;
  background-blend-mode: multiply;
  overflow: hidden;
  padding-top: 5rem;

  .Container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    margin-inline: auto;

    .Left {
      @include Bg;
      background-image: url("../forward_img/connect.jpeg");
      width: 47%;
      height: 500px;
    }

    .Right {
      width: 45%;
      padding-left: 0rem;

      p {
        @include Header_text($header_top_subtext_color);
        font-family: DM Sans, sans-serif !important;
      }

      h3 {
        @include Header($footer_header_color);
        margin: 0rem;
        margin-bottom: 20px;
        margin-top: 8px;
        color: #0b613a;
      }

      .btn-button {
        margin-left: 0rem;
      }
    }
  }
}

.Parcelflow_Join_Section {
  background-color: $white_color;
  width: 100%;
  height: auto;

  .Join {
    width: 100%;
    height: auto;
    padding-top: 8rem;

    .Container {
      width: 90%;
      margin-inline: auto;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .Left {
        width: 45%;

        h5 {
          @include About_Card_Header($hero_header_color);
          margin: 0px;
          margin-bottom: 1rem;
        }

        p {
          @include Header_text($card_text_color);
          margin: 0px;

          &:nth-of-type(2) {
            margin-top: 2rem;
          }

          font-family: DM Sans, sans-serif !important;
        }
      }

      .Avatar {
        @include Bg;
        background-image: url("../forward_img/fmg1.jpg");
        width: 45%;
        height: 450px;
      }
    }
  }

  .Speakers_Section {
    margin-top: 12rem;
    width: 100%;
    height: auto;

    .Show_Btn {
      text-align: center;
      margin: 3rem auto;
    }

    h2 {
      @include Header($footer_header_color);
      text-align: center;
      font-family: "Prompt", sans-serif;
      color: #0b613a;
      margin: 0px;
    }

    p {
      @include Header_text($header_top_subtext_color);
      text-align: center;
      font-family: DM Sans, sans-serif !important;
    }

    .Speakers_Wrapper {
      width: 90%;
      margin-inline: auto;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 1.5rem;
      padding-bottom: 150px;
      overflow: hidden;
      transition: height .4s ease-in-out;

      &.active {
        height: 180rem;
      }

      .Profile {
        margin-top: 3rem;
        cursor: pointer;

        img {
          width: 250px;
          aspect-ratio: 1;
          object-fit: cover;
          border-radius: 100%;
          margin-bottom:1rem;
        }

        .Details {
          .Name {
            @include Header_Mobile($footer_header_color);
          }

          .Product {
            @include Card_header($footer_header_color);
          }

          p {
            @include Header_text($header_top_subtext_color);
            font-family: sans-serif;
            text-align: left;
            margin: 0px;
          }

          font-family: DM Sans, sans-serif !important;
        }
      }
    }

  }
}

.Schedule_Section {
  width: 100%;
  height: auto;
  padding: 5rem 0rem;
  background-color: $forward_bg;

  h5 {
    @include Header_text($header_top_subtext_color);
    text-align: center;
    font-family: sans-serif;
    margin: 0px;
    margin-bottom: .5rem;
  }

  h2 {
    @include Header($footer_header_color);
    text-align: center;
    font-family: Prompt, sans-serif;
    margin: 0px;
    color: #0b613a;
  }

  .Container {
    width: 90%;
    margin-inline: auto;

    .SubText {
      @include Header_Mobile($footer_header_color);
      margin-bottom: 3.5rem;
      margin-top: 8rem;
    }

    .Card {
      width: 100%;
      box-sizing: border-box;
      height: auto;
      border-radius: 3px;
      background-color: $white_color;
      display: flex;
      align-items: flex-start;
      gap: 3rem;
      padding: 3rem;
      margin-top: 2rem;

      .Left {
        .Subtext {
          @include Header_text($header_top_subtext_color);
          font-family: sans-serif;
        }

        .Title {
          @include Header_Mobile($footer_header_color);
          margin-top: 8px;
          margin-bottom: 25px;
          color: #0b613a;
        }

        .Body {
          @include Header_text($header_top_subtext_color);
          font-family: DM Sans, sans-serif !important;
        }
      }

      .Add_Btn {
        @include Button_fill($header_subtext_icon_link_color, $white_color);
        cursor: pointer;
        font-family: "DM Sans", sans-serif !important;
        font-weight: normal;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: .5rem;
        width: 80px;

        &:hover {
          background-color: #45B39D;
          border-style: none;
          line-height: 20px;
        }
      }
    }

  }
}

.Frequently_Question {
  width: 100%;
  height: auto;
  background-color: $white_color;
  box-sizing: border-box;
  padding: 5rem 0rem;

  .Container {
    width: 60%;
    margin-inline: auto;

    h2 {
      @include Header($footer_header_color);
      text-align: center;
      margin-bottom: 7rem;
      color: #0b613a;
    }

    .Card {
      width: 100%;
      height: auto;
      padding: 1rem 0rem;
      box-sizing: border-box;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin: 0px;

      .Title {
        @include Header_text($header_top_subtext_color);
        font-family: DM Sans, sans-serif !important;
        font-weight: 600;
      }

      svg {
        width: 15px;
        height: 15px;
        cursor: pointer;
      }
    }

    .Drop_Down {
      height: 0px;
      overflow: hidden;
      transition: height .4s ease-in-out;
      box-sizing: border-box;

      &.active {
        height: 50px;
      }

      p {
        @include Header_text($white_color);
        font-family: DM Sans, sans-serif !important;
        color: #74788d;
        margin: 0px;
        margin-bottom: .3rem;
      }
    }
  }
}

.Parcelflow_Form {
  width: 100%;
  box-sizing: border-box;
  height: 100vh;
  overflow: hidden;
  background-color: $forward_bg;

  .Container {
    width: 100%;
    margin-inline: auto;
    display: flex;
    align-items: flex-start;

    .Left {
      width: 65%;
      height: 100vh;
      overflow: hidden;
      background-color: #edffff;
      background-position: bottom;
      background-repeat: no-repeat;
      background-size: contain;
      box-sizing: border-box;
      padding-top: 5rem;
      padding-inline: 3rem;

      .Info_Details {
        width: 100%;
        height: auto;

        .Top {
          display: flex;
          align-items: center;
          gap: 1rem;
          // justify-content: center;

          .Time {
            margin: 0rem;
            @include Card_text($header_top_subtext_color);
            font-weight: 500;
          }

          .Box {
            width: 50px;
            height: auto;
            border-radius: 7px;
            background-color: $white_color;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            p {
              @include Card_text($header_top_subtext_color);
              font-weight: 500;
              margin: 0rem;
            }

            h2 {
              @include Card_header($footer_header_color);
              margin: 0rem;
              margin-top: -.5rem;
            }
          }
        }

        h3 {
          @include Header($footer_header_color);
          font-family: DM Sans, sans-serif !important;
          margin: 1rem 0rem;
          color: #0b613a;
        }

        p {
          @include Header_text($header_top_subtext_color);
          font-family: DM Sans, sans-serif !important;

          &:nth-of-type(1) {
            margin-bottom: 4rem;
          }
        }
      }
    }

    .Form {
      width: 35%;
      height: 100vh;
      background-color: $white_color;
      overflow: hidden;
      position: relative;

      .Top {
        width: 100%;
        height: 70px;
        padding-top: 1rem;
        padding-left: 4rem;
        padding-bottom: 0rem;
        box-sizing: border-box;
        box-shadow: 0 0 30px rgb(21 55 44 / 10%) !important;
        overflow: hidden;
        background-color: white;

        .Logo {
          @include Bg;
          width: 180px;
          height: 40px;
          background-image: url("../Image/parcelflow-transparent-logo.png");
        }
      }

      .Contents {
        padding: .5rem 4rem;
        width: 100%;
        height: 100vh;
        box-sizing: border-box;
        overflow-y: scroll;
        padding-bottom: 120px;
        background-color: white;

        &::-webkit-scrollbar {
          width: 3px !important;
        }

        &::-webkit-scrollbar-track {
          background-color: $forebg !important;
          -webkit-border-radius: 1px !important;
        }

        &::-webkit-scrollbar-thumb:vertical {
          background-color: $dotted !important;
          -webkit-border-radius: 0px !important;
          -webkit-width: 10 !important;
        }

        &::-webkit-scrollbar-thumb:vertical:hover {
          background: $dotted !important;
        }

        .Status {
          font-family: sans-serif;
          cursor: pointer;
          @include Card_title($header_subtext_icon_link_color);
          font-weight: 100;
        }

        form {
          h3 {
            @include Header($footer_header_color);
            font-family: DM Sans, sans-serif !important;
            margin: 1rem 0rem;
            font-size: 30px;
          }

          .Box {
            &.Top_Box {
              margin-top: 2rem;
            }

            margin-top: 1.5rem;

            label {
              @include Header_subtext($header_top_subtext_color);
              // font-family: sans-serif;
              // font-weight: 600;
            }
             span{
              @include Card_subtitle($price_error);
              color: $price_error;
             }
            input, select {
              width: 100%;
              height: 45px;
              border-radius: 3px;
              box-sizing: border-box;
              padding: .5rem 1rem;
              outline: none;
              border: 1px solid $header_text_color;
              color: #74788d;
              font-family: DM Sans,sans-serif!important;
              font-size: 16px;
              &.error{
                border-color: $price_error;
              }
            }
          }

          .Terms {
            margin: 2rem 0rem;

            p {
              @include Card_subtitle($header_top_subtext_color);
              font-family: "DM Sans", sans-serif !important;
              margin-bottom: 1rem;
            }
          }

          .Sign_Up {
            @include Button_fill($header_subtext_icon_link_color, $white_color);
            cursor: pointer;
            font-family: "DM Sans", sans-serif !important;
            font-weight: normal;
            text-align: center;

            &:hover {
              background-color: #45B39D;
              border-style: none;
              line-height: 20px;
            }
          }
          .Pay_Btn{
            width: 100%;
            @include Button_fill($header_subtext_icon_link_color, $white_color);
            cursor: pointer;
            font-family: "DM Sans", sans-serif !important;
            font-weight: normal;
            text-align: center;
            margin-top: 2rem;

            &:hover {
              background-color: #45B39D;
              border-style: none;
              line-height: 20px;
            }
          }

          .Btn_Wrap {
            margin-top: 3rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
           
          }
        }
      }
    }
  }
}


@media (max-width: 768px) {
  .Parcelflow_Forward {
    height: auto;

    .Container {
      flex-direction: column-reverse;
      align-items: flex-start;
      justify-content: center;
      gap: 3rem;
      width: 98%;

      .Right {
        width: 100%;
        padding-top: 3rem;
      }

      .Left {
        width: 55%;
        height: 300px;
        padding: 0rem;
        margin-bottom: 3.5rem;
      }
    }
  }

  .Parcelflow_Join_Section {
    .Join {
      .Container {
        width: 90%;
        flex-direction: column;
        gap: 5rem;

        .Left {
          width: 100%;
        }

        .Avatar {
          width: 62%;
          height: 300px;
        }
      }
    }

    .Speakers_Section {
      .Speakers_Wrapper {
        grid-template-columns: repeat(2, 1fr);
      }

    }
  }
  .Frequently_Question {
    .Container {
      width: 80%;
    }
  }
  .Parcelflow_Form {
    .Container {
      .Left {
        display: none;
      }

      .Form {
        width: 100%;

        .Contents {
          width: 80%;
          height: 95vh;
          margin-inline: auto;
          padding: 3rem 1rem;
        }
      }
    }
  }
}

@media (max-width: 400px) {
  .Parcelflow_Forward {
    .Container {
      width: 95%;
      gap: 2rem;

      .Right {
        width: 100%;
        padding-top: 3rem;

        h3 {
          @include Header_Mobile($footer_header_color);
        }
      }

      .Left {
        width: 100%;
        height: 300px;
        padding: 0rem;
        margin-bottom: 3.5rem;
      }
    }
  }

  .Parcelflow_Join_Section {
    .Join {
      padding-top: 4rem;

      .Container {
        width: 90%;
        flex-direction: column;
        gap: 5rem;

        .Left {
          width: 100%;

          h5 {
            @include About_Card_Header_Mobile;
          }
        }

        .Avatar {
          width: 100%;
          height: 300px;
        }
      }
    }

    .Speakers_Section {
      margin-top: 12rem;
      width: 95%;
      margin-inline: auto;

      h2 {
        @include Header_Mobile($footer_header_color);
      }

      .Speakers_Wrapper {
        grid-template-columns: repeat(1, 1fr);
        justify-content: center;
        .Profile{
          text-align: center;
          img{
            width: 150px;
          }
          .Details{
            p{
              text-align: center!important;
            }
          }
        }
      }

    }
  }

  .Profile_Modal {
    width: 300px;
    flex-direction: column;

    img {
      width: 200px;
      margin-bottom: 2rem;
    }

    .Right {
      width: 100%;
      height: auto;

      .Title {
        width: 100%;
      }
    }
  }
  .Schedule_Section {
    h5, h2 {
      text-align: left;
    }

    .Container {
      width: 95%;

      .SubText {
        @include Header_Mobile($footer_header_color);
        margin-top: 3rem;
      }

      .Card {
        flex-direction: column;
        gap: 1rem;
        padding: 1rem;

        .Left {
          .Title {
            @include Header_Mobile($footer_header_color);
          }
        }
      }

    }
  }
  .Frequently_Question {
    .Container {
      width: 95%;

      h2 {
        @include Header_Mobile($footer_header_color);
        margin-bottom: 3rem;
      }
    }
  }
  .Parcelflow_Form {
    height: 100vh;

    .Container {
      .Left {
        display: none;
      }

      .Form {
        width: 100%;

        .Contents {
          padding: 3rem 1rem;
          width: 100%;
          height: 95vh;

          form {
            h3 {
              @include Header_Mobile($footer_header_color);
            }
          }
        }
      }
    }
  }
}

.amount-field{
  width: 100%;
  height: 45px;
  border-radius: 3px;
  box-sizing: border-box;
  padding: .5rem 1rem;
  outline: none;
  border: 1px solid $header_text_color;
  color: #74788d;
  font-family: DM Sans,sans-serif!important;
  font-size: 16px;
}

.payment-button{
  margin-top: 30px;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;
  line-height: 20px;
  letter-spacing: 0.5px;
  fill: #FFFFFF;
  color: #FFFFFF;
  background-color: #15AB68;
  border-style: none;
  border-radius: 3px;
  border-color: #15AB68;
  transition: all 0.15s ease;
  padding: 8px 20px;
  cursor: pointer;
  font-family: "DM Sans", sans-serif !important;
  font-weight: normal;
  text-align: center;
}

.payment-header{
  color: #1A1A1A;
  font-size: 30px;
  font-weight: 400;
  line-height: 1.1em;
  font-family: DM Sans, sans-serif !important;
  margin: 1rem 0rem;
  text-align: center;
}

.payment-subtext{
  color: #454545;
  font-size: 18px;
  line-height: 1.5em;
  font-family: DM Sans, sans-serif !important;
}