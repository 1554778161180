
$header_color:#0b613a;
$hero_header_color:#0b613a;
$about_subheader_text_color:#0b613a;
$header_text_color:#74788d;
$header_subtext_icon_link_color:#15AB68;
$header_top_subtext_color:#454545;
$card_header_color:#0b613a;
$card_text_color:#5A5A5A;
$card_title_subtitle_color:#2E2E2E;
$checkbox_text_color:#191825;
$white_color:#FFFFFF;
$btn_bg_color:#2D2D2D;
$btn_bg_hover_color:#FF848400;
$btn_hover_color:#2D2D2D;
$reach_out_bg:#64D8A4;
$footer_header_color:#1A1A1A;
$forebg:rgba(226, 223, 223, 0.4);
$section_b_container_color:#FEF9F6;
$radiant:radial-gradient(at bottom left, #FEF8DC 70%, #FFD200 100%);
$parallax_blend_mode_color:#121212;
$border_color:#0000001F;
$banner_color:#F7F7F7;
$img_bg_color:#d6d6d6;
$conference_bg:rgb(252, 229, 198);
$green_bg:rgb(21, 55, 44);
$faq_color:#fc7a62;
$faq2_color:#6afdef;
$faq3_color:#ffd954;
$price_gold:#f3b521;
$price_error:#db0629;
$price_blue:#0057ff;
$dotted:rgba(99, 103, 102, 0.5);

$close_svg_color:rgba(245, 244, 243, 0.6);
$forward_bg:rgba(238,235,234,1);