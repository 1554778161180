@import './mixin.scss';
@import './variables.scss';

.Header_Wrappers{
    width: 100%;
    height: auto;
    position: fixed;
    box-shadow: 0 0 30px rgb(21 55 44 / 10%)!important;
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: 99999;
    overflow-x: hidden; 
    margin-inline: auto; 
}


.navbar_fill{
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
    background-color: #edffff;
    &.active-dropdown{
        // background-color:  $header_subtext_icon_link_color;
    }
    &.none-active-dropdown{
        background-color: $white_color;
    }
    .Appbar{
        transition: background-color .2s ease;
        padding-left: 3rem;
        padding-right: 3rem;
        display:flex;
        align-items: center;
        justify-content: space-between;
        height: 70px!important;
        // background-color:red;
        .toolbar-left-content{
            display: flex;
            align-items: center;
            justify-content: flex-start;
            height: 100%;
            box-sizing: border-box;
           ul{
            
            height: 100%;
            li{
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                a{
                    @include Navbar($footer_header_color);
                    font-family: "DM Sans", sans-serif !important;
                    font-weight: normal;
                    cursor: pointer;
                    margin-top: 15px;
                }
            }
           }
            }
            .toolbar-right-content{
                display: flex;
                align-items: center;
                img{
                    width:27px;
                    height:27px;
                    margin-left: 1rem;
                }
                .img_hamburger{
                    display: none;
                }
            }
    }
}

.App-header{
 list-style:none;
 display: flex;
 box-sizing: border-box;
 li{ 
    height: 100%;
    box-sizing: border-box;
    &.active_li{
        color: $header_subtext_icon_link_color;
        border-bottom:1px solid $header_subtext_icon_link_color;
    }
 }
}
.active-link{
    color: $header_subtext_icon_link_color!important;
    border-bottom: 3px solid $header_subtext_icon_link_color!important; 
}

.mobile{
    display: none;
    height: 100vh;
    overflow: hidden;
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, 0.678);
    transition: .4s ease-in-out;
    z-index: 109999;
    position: fixed;
    top: 0px;
    right: 0px;
    &.on-active{
        width: 100vw;
    }
    &.not-active{
        width: 0vw;
    }
    .Container{
        width: 70vw;
        height:100vh;
        background-color:$green_bg;
        overflow: hidden;
        box-sizing: border-box;
        position: absolute;
        top: 0px;
        right: 0px;
        // display: flex;
        // align-items: flex-start;
        .Wrapper_Relative{
            position: relative;
            overflow: hidden;
            width: 100%;
            // background-color: red;
            .Top_Section{
                height:71px;
                width: 100%;
                box-sizing: border-box;
                border-bottom:1px solid #A6ACAF;
                padding:.4rem 1rem;
                padding-top:1rem;
                display: flex;
                img{
                    width: 30px;
                    height: 30px;
                    &:nth-of-type(1){
                        // border: 2px solid red;
                    }
                    &.close{
                       margin-left: auto!important;
                    }
                }
            }
            .List_of_Link{
                width: 100%;
                height:auto;
                box-sizing: border-box;
                padding:1rem 1.5rem;
                padding-right: .5rem;
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                flex-direction: column;
                clear: both;
                ul{
                    margin: 0px;
                    padding: 0px;
                    width: 100%;
                    li{
                        display: flex;
                        align-items:center;
                        justify-content: space-between;
                        @include Card_header($white_color);
                        font-family: "DM Sans", sans-serif !important;
                        font-weight: normal;
                        font-size: 18px;
                        padding: 0px;
                        margin: 0px;
                        margin-bottom: 1rem;
                        a{
                            color: $white_color
                        }
                        img{
                            width:25px;
                            height:25px;
                        }
                    }
                }
                .btn_box{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    margin-top:4rem;
                    width:90%;
                    height:auto;
                    margin-right:1.5rem;
                    padding:1rem 1rem;
                    // border: 2px solid red;
                    overflow: hidden;
                    box-sizing: border-box;
                    a{
                        width: 100%!important;
                       span.btn-button{
                        margin: 0px;
                        width: 100%!important;
                        text-align: center!important;
                        margin-bottom:.8rem;
                        display: block!important;
                    }
                    }
                    
                }
            }
       
        }
    }
}


@media (max-width:768px) {
    .navbar_fill{
        position: fixed;
        box-shadow: 0 0 30px rgb(21 55 44 / 10%)!important;
        top: 0px;
        left: 0px;
        right: 0px;
        z-index: 99999;
        overflow-x: hidden; 
        margin-inline: auto; 
        .Appbar{
            padding-left:1rem;
            padding-right:1rem;
            .toolbar-left-content{
                .App-header{
                    display: none;
                }
                }
                .toolbar-right-content{
                    .btn-ouline,.btn-button{
                        display: none;
                    }
                    .img_hamburger{
                        display: flex;
                        height: 10px!important;
                    }
                }
        }
    }
    .mobile{
        display: block;
        height: 100vh;
        overflow: hidden;
        box-sizing: border-box;
        background-color: rgba(0, 0, 0, 0.678);
        transition: .4s ease-in-out;
        z-index: 109999;
        position: fixed;
        top: 0px;
        right: 0px;
        &.on-active{
            width: 100vw;
        }
        &.not-active{
            width: 0vw;
        }
        .Container{
            width:55vw;
            height:100vh;
            background-color:$green_bg;
            overflow: hidden;
            // overflow-y: scroll;
            // scroll-behavior: smooth;
            box-sizing: border-box;
            position: absolute;
            top: 0px;
            right: 0px;
            padding-top: 0rem;
            // display: flex;
            // align-items: flex-start;
            .Wrapper_Relative{
                position: relative;
                border:1px solid red;
                width: 100%;
                height: 100vh;
                .Top_Section{
                    height:71px;
                    width: 100%;
                    box-sizing: border-box;
                    border-bottom:1px solid #A6ACAF;
                    padding:.4rem 1rem;
                    padding-top:1rem;
                    display: flex;
                    img{
                        width: 30px;
                        height: 30px;
                        &:nth-of-type(1){
                            // border: 2px solid red;
                        }
                        &.close{
                           margin-left: auto!important;
                        }
                    }
                }
                .List_of_Link{
                    width: 100%;
                    height:auto;
                    box-sizing: border-box;
                    padding:2.5rem 1.5rem;
                    padding-right: .5rem;
                    display: flex;
                    align-items: flex-start;
                    justify-content: flex-start;
                    flex-direction: column;
                    clear: both;
                    ul{
                        margin: 0px;
                        padding: 0px;
                        width: 100%;
                        li{
                            display: flex;
                            align-items:center;
                            justify-content: space-between;
                            @include Card_header($white_color);
                            font-family: "DM Sans", sans-serif !important;
                            font-weight: normal;
                            font-size: 18px;
                            padding: 0px;
                            margin: 0px;
                            margin-bottom: 1rem;
                            img{
                                width:25px;
                                height:25px;
                            }
                        }
                    }
                    .btn_box{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        margin-top:4rem;
                        width:90%;
                        height:auto;
                        margin-right:1.5rem;
                        padding:1rem 1rem;
                        // border: 2px solid red;
                        overflow: hidden;
                        box-sizing: border-box;
                        .btn-button{
                            margin: 0px;
                            width: 85%!important;
                            text-align: center!important;
                            margin-bottom:.8rem;
                        }
                        .btn-ouline{
                            margin: 0px;
                            width: 85%!important;
                            text-align: center!important;
                        }
                    }
                }
                .product_mobile{
                width: 100%;
                height: 100%;
                position: absolute;
                top:71px;
                right:-100rem;
                transition: .4s ease-in-out;
                &.product_mobile_isActive{
                    right:0rem;
                }
                &.product_mobile_notActive{
                    right:-100rem;
                }
                .Container{
                    width: 100%;
                    overflow-x: hidden;
                    overflow-y: scroll;
                    margin-inline: auto;
                    display: flex;
                    align-items: flex-start;
                    justify-content:flex-start;
                    flex-direction: column;
                    padding-top: 1rem;
                    padding-left: 1.5rem;
                    .left_content{
                        width: 100%;
                    }
                    ul{
                        padding: 0px;
                        margin: 0px;
                        li{
                            &.top_title{
                                @include Card_subtitle($header_subtext_icon_link_color );
                                margin: 0px;
                                margin-bottom:1rem;
                            }
                            a{
                            @include Card_header($white_color);
                            font-family: "DM Sans", sans-serif !important;
                            font-weight: normal;
                            font-size: 18px;
                            margin: 0px;
                            &:hover{
                                text-decoration: underline $white_color;
                            }
                            }
                            margin-bottom:1rem;
                        }
                    }
                }
            }
            .resources_mobile{
                width: 100%;
                height: 100%;
                position: absolute;
                top:71px;
                right: -100rem;
                transition: .4s ease-in-out;
                &.resources_mobile_isActive{
                    right:0px;
                }
                &.resources_mobile_notActive{
                    right:-100rem;
                }
                .Container{
                    width: 100%;
                    height: auto;
                    scroll-behavior: smooth;
                    overflow-x: hidden;
                    margin-inline: auto;
                    display: flex;
                    align-items: flex-start;
                    justify-content:flex-start;
                    flex-direction: column;
                    padding-top: 1rem;
                    padding-bottom:3.5rem;
                    padding-left: 1.5rem;
                    .left_content{
                        width: 100%;
                        padding-bottom: 4rem;
                        border-bottom: 2px solid $header_subtext_icon_link_color;
                        ul{
                            padding: 0px;
                            margin: 0px;
                            li{
                                a{
                                @include Card_header($white_color);
                                font-family: "DM Sans", sans-serif !important;
                                font-weight: normal;
                                font-size: 18px;
                                margin: 0px;
                                &:hover{
                                    text-decoration: underline $white_color;
                                }
                                }
                                margin-bottom:1rem;
                            }
                        }
                    }
                    .right_content{
                        width: 100%;
                        height: auto;
                        display: flex;
                        align-items: flex-start;
                        justify-content: space-between;
                        box-sizing: border-box;
                        flex-direction: column;
                        padding-right:1.5rem;
                        margin-top:3.5rem;
                        h6{
                            @include Card_subtitle($header_subtext_icon_link_color );
                            margin: 0px;
                            font-family: 'Lato', sans-serif;
                            // margin-bottom:1rem;
                        }
                        img{
                            border-radius: 5px;
                            width:100%;
                            // height:250px;
                            margin:3rem 0rem;
        
                        }
                        h4{
                            @include Header_text($white_color);
                            margin:.3rem 0px;
                            margin-bottom:.7rem;
                            font-family: 'Lato', sans-serif;
                        }
                        p{
                            @include Card_title($white_color);
                            margin: 0px;
                            font-family: 'Lato', sans-serif;
                            margin-bottom:4rem;
                            line-height: 1.5;
                        }
            
                    }
                }
            }
            .about_mobile{
                width: 100%;
                height: 100%;
                position: absolute;
                top:120px;
                right: -100rem;
                transition: .4s ease-in-out;
                &.about_mobile_isActive{
                    top:120px;
                    right:0px;
                }
                &.about_mobile_notActive{
                    top:120px;
                    right:-100rem;
                }
                .Container{
                    width: 100%;
                    height: auto;
                    scroll-behavior: smooth;
                    overflow: hidden;
                    margin-inline: auto;
                    display: flex;
                    align-items: flex-start;
                    justify-content:flex-start;
                    flex-direction: column;
                    padding-top:3.5rem;
                    padding-bottom:3.5rem;
                    padding-left: 1.5rem;
                    .left_content{
                        width: 100%;
                        padding-bottom:3.5rem;
                        border-bottom: 2px solid $header_subtext_icon_link_color;
                        ul{
                            padding: 0px;
                            margin: 0px;
                            li{
                                a{
                                @include Card_header($white_color);
                                font-family: 'Noto Sans Georgian', sans-serif;
                                margin: 0px;
                                &:hover{
                                    text-decoration: underline $white_color;
                                }
                                }
                                margin-bottom:1rem;
                            }
                        }
                    }
                    .right_content{
                        width: 100%;
                        height: auto;
                        display: flex;
                        align-items: flex-start;
                        justify-content: space-between;
                        box-sizing: border-box;
                        flex-direction: column;
                        padding-right:1.5rem;
                        margin-top:3.5rem;
                        h6{
                            @include Card_subtitle($header_subtext_icon_link_color );
                            margin: 0px;
                            font-family: 'Lato', sans-serif;
                            // margin-bottom:1rem;
                        }
                        img{
                            border-radius: 5px;
                            width:100%;
                            // height:250px;
                            margin:3rem 0rem;
        
                        }
                        h4{
                            @include Header_text($white_color);
                            margin:.3rem 0px;
                            margin-bottom:.7rem;
                            font-family: 'Lato', sans-serif;
                        }
                        p{
                            @include Card_title($white_color);
                            margin: 0px;
                            font-family: 'Lato', sans-serif;
                            margin-bottom:4rem;
                            line-height: 1.5;
                        }
            
                    }
                }
            }
            .community_mobile{
                width: 100%;
                height: 100%;
                position: absolute;
                top:71px;
                right: -100rem;
                transition: .4s ease-in-out;
                &.community_mobile_isActive{
                    right:0px;
                }
                &.community_mobile_notActive{
                    right:-100rem;
                }
                .Container{
                    width: 100%;
                    height: auto;
                    scroll-behavior: smooth;
                    overflow: hidden;
                    margin-inline: auto;
                    display: flex;
                    align-items: flex-start;
                    justify-content:flex-start;
                    flex-direction: column;
                    padding-top: 1.3rem;
                    padding-bottom:3rem;
                    padding-left: 1.5rem;
                    .left_content{
                        width: 100%;
                        padding-bottom:3.5rem;
                        border-bottom: 2px solid $header_subtext_icon_link_color;
                        ul{
                            padding: 0px;
                            margin: 0px;
                            li{
                                a{
                                @include Card_header($white_color);
                                font-family: "DM Sans", sans-serif !important;
                                font-weight: normal;
                                margin: 0px;
                                font-size: 18px;
                                &:hover{
                                    text-decoration: underline $white_color;
                                }
                                }
                                margin-bottom:1rem;
                            }
                        }
                    }
                    .right_content{
                        width: 100%;
                        height: auto;
                        display: flex;
                        align-items: flex-start;
                        justify-content: space-between;
                        box-sizing: border-box;
                        flex-direction: column;
                        padding-right:1.5rem;
                        margin-top:3.5rem;
                        margin-bottom: 5rem;
                        h6{
                            @include Card_subtitle($header_subtext_icon_link_color );
                            margin: 0px;
                            font-family: 'Lato', sans-serif;
                            // margin-bottom:1rem;
                        }
                        img{
                            border-radius: 5px;
                            width:100%;
                            // height:250px;
                            margin:3rem 0rem;
        
                        }
                        h4{
                            @include Header_text($white_color);
                            margin:.3rem 0px;
                            margin-bottom:.7rem;
                            font-family: 'Lato', sans-serif;
                        }
                        p{
                            @include Card_title($white_color);
                            margin: 0px;
                            font-family: 'Lato', sans-serif;
                            margin-bottom:4rem;
                            line-height: 1.5;
                        }
            
                    }
                }
            }
            }
        }
    }
    
}

@media (max-width:400px){
    .mobile{
        min-height: auto;
        .Container{
            width:100vw;
            margin-inline: auto;
            min-height: auto;
           .Wrapper_Relative{
            .Top_Section{
                height:71px;
                width: 100%;
                box-sizing: border-box;
                border-bottom: 2px solid $white_color;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding:.4rem 1rem;
                img{
                    width: 30px;
                    height: 30px;
                }
            }
            .List_of_Link{
                width: 100%;
                height:auto;
                box-sizing: border-box;
                padding:1rem 1.5rem;
                padding-right: 0rem;
                ul{
                    margin: 0px;
                    padding: 0px;
                    width: 100%;
                    li{
                        @include About_Text($white_color);
                        margin-bottom: 1rem;
                        img{
                            width:50px;
                            height:50px;
                        }
                    }
                }
                .btn_box{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    flex-direction: column;
                    margin-top:4rem;
                }
            }
            .product_mobile{
                width: 100%;
                height: 100%;
                position: absolute;
                top: 71px;
                right:-100rem;
                transition: .4s ease-in-out;
                &.product_mobile_isActive{
                    right:0rem;
                }
                &.product_mobile_notActive{
                    right:-100rem;
                }
                .Container{
                    width: 100%;
                    overflow: hidden;
                    margin-inline: auto;
                    display: flex;
                    align-items: flex-start;
                    justify-content:flex-start;
                    flex-direction: column;
                    padding-top:1rem;
                    padding-left: 1.5rem;
                    .left_content{
                        width: 100%;
                    }
                    ul{
                        padding: 0px;
                        margin: 0px;
                        li{
                            &.top_title{
                                @include Card_subtitle($header_subtext_icon_link_color );
                                margin: 0px;
                                margin-bottom:1rem;
                            }
                            a{
                            @include Card_header($white_color);
                            font-family: 'Noto Sans Georgian', sans-serif;
                            margin: 0px;
                            &:hover{
                                text-decoration: underline $white_color;
                            }
                            }
                            margin-bottom:1rem;
                        }
                    }
                }
            }
           }

        }
    }
}