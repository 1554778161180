@import './mixin.scss';
@import './variables.scss';


.Edit_Modal{
    .Form {
        width:100%;
        height: 70vh;
        background-color: $white_color;
        overflow: hidden;
        position: relative;
  
        .Top {
          width: 100%;
          height: 60px;
          padding-top:.5rem;
          padding-left: 3rem;
          padding-bottom: 0rem;
          box-sizing: border-box;
          box-shadow: 0 0 30px rgb(21 55 44 / 10%) !important;
          overflow: hidden;
          background-color: white;
  
          .Logo {
            @include Bg;
            width: 180px;
            height: 40px;
            background-image: url("../Image/parcelflow-transparent-logo.png");
          }
        }
  
        .Contents {
          padding:.5rem 3rem;
          width: 100%;
          height: 70vh;
          box-sizing: border-box;
          overflow-y: scroll;
          padding-bottom: 120px;
          background-color: white;
  
          &::-webkit-scrollbar {
            width: 3px !important;
          }
  
          &::-webkit-scrollbar-track {
            background-color: $forebg !important;
            -webkit-border-radius: 1px !important;
          }
  
          &::-webkit-scrollbar-thumb:vertical {
            background-color: $dotted !important;
            -webkit-border-radius: 0px !important;
            -webkit-width: 10 !important;
          }
  
          &::-webkit-scrollbar-thumb:vertical:hover {
            background: $dotted !important;
          }
  
          .Status {
            font-family: sans-serif;
            cursor: pointer;
            @include Card_title($header_subtext_icon_link_color);
            font-weight: 100;
          }
  
          form {
            h3 {
              @include Header($footer_header_color);
              font-family: DM Sans, sans-serif !important;
              margin: 1rem 0rem;
            }
  
            .Box {
              &.Top_Box {
                margin-top: 2rem;
              }
  
              margin-top: 1.5rem;
  
              label {
                @include Header_subtext($header_top_subtext_color);
                // font-family: sans-serif;
                // font-weight: 600;
              }
  
              input, select {
                width: 100%;
                height: 45px;
                border-radius: 3px;
                box-sizing: border-box;
                padding: .5rem 1rem;
                outline: none;
                border: 1px solid $header_text_color;
                color: #74788d;
                font-family: DM Sans,sans-serif!important;
                font-size: 16px;
              }
            }
  
            .Sign_Up {
              @include Button_fill($header_subtext_icon_link_color, $white_color);
              cursor: pointer;
              font-family: "DM Sans", sans-serif !important;
              font-weight: normal;
              text-align: center;
                margin-top: 2rem;
              &:hover {
                background-color: #45B39D;
                border-style: none;
                line-height: 20px;
              }
            }
  
            .Btn_Wrap {
              margin-top: 3rem;
              display: flex;
              align-items: center;
              justify-content: space-between;
            }
          }
        }
      }
}


.Profile_Details{
    box-sizing: border-box;
    width: 100%;
    height:100vh;
    overflow: hidden;
    .Container{
        width: 70%;
        height: auto;
        margin-inline: auto;
      .Profile_Info{
        width: 100%;
        h2{
            @include Hero_header($footer_header_color); 
            margin: 0px;
            margin-top:2rem;
        }
        .Profile_Bottom{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 2rem;
            h4{
              @include Card_header($footer_header_color);
              font-family: sans-serif;
            }
            .Edit_Btn{
                @include Card_header($footer_header_color);
                font-family: sans-serif;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
            }
        }
      }
      .Card{
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 8.7px 18px rgb(0 0 0 / 5%);
        padding: 28px 40px;
        border-radius: 12px;
        box-sizing: border-box;
        gap: 4rem;
        .User_Name{
            @include About_Hero_Header($footer_header_color);
        }
        .Details{
            .Box{
                margin-bottom:1rem;
                h3{
                    @include About_Card_Header_Mobile;
                    color: $footer_header_color;
                    margin: 0px;
                }
                h5{
                  font-size: 16px;
                  line-height: 20px;
                  font-weight: 700;  
                  color: $footer_header_color;
                  margin: 0px;
                }
                .Gray_Text{
                    color: $header_text_color;
                    font-size: 12px;
                    line-height: 20px;
                }
                .Light_Text{
                    @include Card_text($footer_header_color);
                    font-family: sans-serif;
                }
            }
        }
      }
    }
}

@media (max-width:768px){
    .Profile_Details{
        .Container{
            width: 90%;
            margin-top: 3rem;
        }
    }
}

@media (max-width:400px){
    .Profile_Details{
        height:auto;
        .Container{
            width: 95%;
          .Profile_Info{
            h2{
                @include Hero_header_Mobile; 
            }
            .Profile_Bottom{
                flex-direction: column;
                align-items: flex-start;
                gap:.5rem;
                h4{
                  margin: 0px;
                }
            }
          }
          .Card{
            padding-inline: 1rem;
            flex-direction: column;
            align-items: flex-start;
            gap:1rem;
            .User_Name{
                @include About_Hero_Header_Mobile;
            }
          }
        }
    }
    .Edit_Modal{
        width: 300px;
        .Form{
            .Top,.Contents{
                padding-inline: 1rem;
            }
        }
    }
}